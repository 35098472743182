import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import InvitationForm from 'pages/home/Ready/InvitationForm'

export default function Ready() {
  return (
    <Box as="section" id='contact'>
      <Box
        maxW="3xl"
        mx="auto"
        px={{ base: '6', lg: '8' }}
        py={{ base: '16', sm: '20' }}
        textAlign="center"
      >
        <Text fontWeight="semibold" color={useColorModeValue('brand.500', 'brand.500')}>
          Start your 30 day free trial today.
        </Text>
        <Heading
          my="4"
          as="h2"
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="bold"
          letterSpacing="tight"
          lineHeight="1.2"
        >
          Ready to Walawong?
        </Heading>
        <Text fontSize="lg" maxW="2xl" mx="auto" mb="8" color={useColorModeValue('gray.600', 'gray.400')}>
          You can sign up to get started or request a demo from our team.<br />
          Enter your work email below and we will answer any questions you may have.
        </Text>
        <InvitationForm />
      </Box>
    </Box>
  )
}
