import React from 'react'
import Intro from 'pages/home/Intro'
// import Partner from 'pages/home/Partner'
import Features from 'pages/home/Features'
import UseCase from 'pages/home/UseCase'
import Pricing from 'pages/home/Pricing'
import Ready from 'pages/home/Ready'
import Seo from 'components/Seo'
import { PageProps, graphql } from 'gatsby'
import { BlogShort } from 'graphqlTypes'
import Layout from 'components/Layout'
import BlogPage from 'pages/home/BlogPage'
import Benefits from 'pages/home/Benefits'

interface Data {
  allContentfulPost: {
    edges: Array<{
      node: BlogShort
    }>
  }
}
// tét

const IndexPage: React.FC<PageProps<Data>> = ({ data }) => {
  return (
    <Layout isFullWidth>
      <Seo title="Home" />
      <Intro />
      <Benefits />
      <Features />
      {/* <Partner /> */}
      <UseCase />
      <Pricing />
      <BlogPage allContentfulPost={data.allContentfulPost} />
      <Ready />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query GetFeaturedPosts {
    allContentfulPost(
      filter: { isFeatured: { eq: true } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          createdAt(formatString: "DD/MM/YYYY")
          slug
          title {
            title
          }
          author {
            name
            avatar {
              fixed(cropFocus: CENTER, height: 120, width: 120) {
                src
                srcSet
                width
                height
              }
            }
          }
          heroImage {
            fixed(width: 600) {
              src
              srcSet
              width
              height
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`
